.c-offcanvas-content-wrap {
	min-height: 100vh;
}

.pg-checkout {
	.form-control {
		margin-bottom: 10px;
	}
}

.hidden {
	display: none;
}

.product-card {
	.variation-name {
		display: none;
	}
}

.input-group {
	.input-group-addon {
		height: calc(2.25rem + 2px);
		font-size: 0.7em;
		border-right: 1px solid #ced4da;
		border-top: 1px solid #ced4da;
		border-bottom: 1px solid #ced4da;
		text-transform: uppercase;
		line-height: 2.25rem;
		padding-left: 5px;
		padding-right: 5px;
		font-weight: bold;
	}
}